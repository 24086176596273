import React, { useState, useEffect } from "react";
import { Box, CircularProgress } from "@mui/material";
import { keyframes } from "@emotion/react";
import personalData from '../images/WomanReading.png';
import {
  AppBar,
  Toolbar,
  Avatar,
  Typography,
} from '@mui/material';
import logo from '../images/logo.png';
import starsImg from '../images/StarsGold.png'; // Import the stars image

// Keyframes for fade-in animation
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 0.65;
  }
`;

const starStyles = [
  {
    top: "15%",
    left: "25%",
    transform: "rotate(-20deg) scale(3)",
    delay: "0s",
  },
  {
    top: "15%",
    left: "87%",
    transform: "rotate(55deg) scale(1.5)",
    delay: "0.2s",
  },
  {
    top: "35%",
    left: "70%",
    transform: "rotate(150deg) scale(3.2)",
    delay: "0.4s",
  },
  {
    top: "80%",
    left: "55%",
    transform: "rotate(-180deg) scale(2.5)",
    delay: "0.6s",
  },
  {
    top: "80%",
    left: "-3.5%",
    transform: "rotate(58deg) scale(1.5)",
    delay: "0.8s",
  },
];

const LoadingScreen = () => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // Simulate delay for the fade-in effect to complete
  useEffect(() => {
    const timer = setTimeout(() => setImageLoaded(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Box>
        <AppBar position="fixed" sx={{
          bgcolor: '#34495E',
          boxShadow: '5px',
        }}>
          <Toolbar>
            <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
              <Avatar src={logo} alt="Logo" sx={{ width: 70, height: 70 }} />
              <Typography variant="h5" component="div">
                Sapnasis
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      <Box
        sx={{
          position: 'relative',
          height: '100vh',
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#2C3E50",
          overflow: 'hidden',
        }}
      >
        {starStyles.map((style, index) => (
          <Box
            key={index}
            component="img"
            src={starsImg}
            alt="Star"
            sx={{
              position: 'absolute',
              ...style,
              animation: `${fadeIn} 3s ease-in-out forwards`,
              animationDelay: style.delay,
              width: '80px', // Adjust size as needed
              zIndex: 0, // Place behind the content
              opacity: 0, // Ensure stars are initially invisible
            }}
          />
        ))}
        <Box 
          sx={{ 
            width: '100%', 
            textAlign: 'center', 
            mt: 10, 
            opacity: imageLoaded ? 1 : 0,  // Control the opacity based on image load
            transition: 'opacity 1s ease-in-out', // Smooth transition for opacity
            zIndex: 1, // Ensure it stays above the stars
          }}
        >
          <img 
            src={personalData} 
            alt="personal-data" 
            style={{ maxWidth: '100%' }} 
            onLoad={() => setImageLoaded(true)}  // Set the image as loaded once it fully loads
          />
        </Box>
        <CircularProgress 
          size={85} 
          sx={{ 
            animation: `${fadeIn} 1s ease-in-out`,
            color:"white",
            zIndex: 1, // Ensure it stays above the stars
          }} 
        />
      </Box>
    </>
  );
};

export default LoadingScreen;
